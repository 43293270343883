import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';


const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;
  const joeyFeelings = ['alive and well.', 'thriving.', 'probably napping.', 'looking for pets.', 'living the best life.', 'concerned about thunder. You should be too.'];

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [joeyFeeling, setJoeyFeeling] = useState();

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }

    var jf = joeyFeelings[Math.floor(Math.random() * joeyFeelings.length)];
    setJoeyFeeling(jf);
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            <ProjectImg className="hero-image" alt={'Joey'} filename={'Joey.jpg'} />
            Joey is {joeyFeeling || 'alive and well.'}
            {/* <span className="text-color-main">{name || 'Your Name'}</span> */}
            {/* <br />
            {subtitle || "I'm the Unknown Developer."} */}
          </h1>
          <a href="https://www.hitwebcounter.com" target="_blank">
          <img src="https://hitwebcounter.com/counter/counter.php?page=7760446&style=0040&nbdigits=5&type=page&initCount=0" title="Free Counter" Alt="web counter"   border="0" /></a>    
        </Fade>
        {/* <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade> */}
      </Container>
    </section>
  );
};

export default Header;
